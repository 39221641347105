<template>
  <div>
    <resource
      class="webhook-resource"
      :can-add="false"
      :can-delete="false"
      :can-update="false"
      :show-speed-dial="false"
      :index-request="indexHandler"
      :meta="{name: $tc('webhook.title', 1), namePlural: $tc('webhook.title', 2)}"
      :table-content="tableContent">
    </resource>
  </div>
</template>

<script lang="js">
import { index} from '@/api/endpoints/webhook.js';
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { mapGetters } from "vuex";
import dayJs from "@/plugins/dayJs.js";

export default {
  name: 'WebhookResource',
  components: {
    Resource,
  },
  props: {
    client: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters("profile", ["can"]),
    tableContent() {
      return [
        {
          text: this.$t('webhook.fields.eventType'),
          align: 'left',
          sortable: true,
          value: 'eventType',
        },
        {
          text: this.$t('webhook.fields.isActive'),
          align: 'left',
          sortable: true,
          value: 'isActive',
          columnType: 'checkbox',
        },
        {
          text: this.$t('webhook.fields.url'),
          align: 'left',
          sortable: true,
          value: 'url',
        },
        {
          text: this.$t('webhook.fields.createdAt'),
          align: 'left',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: this.$t('webhook.fields.deletedAt'),
          align: 'left',
          sortable: true,
          value: 'deletedAt',
        },
      ];
    },
  },
  data() {
    return { dialog: false };
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'client.index' },
        text: this.$tc('client.title', 2),
      },
      {
        exact: true,
        to: { name: 'client.connectors' },
        text: this.client.name,
      },
      {
        exact: true,
        to: { name: 'client.webhook' },
        text: this.$tc('webhook.title', 2),
      },
    ]);
  },
  methods: {
    async indexHandler() {
      const response = await index(this.$route.params.clientId, ...arguments);

      response.data.data.map(data => {
        data.createdAt = dayJs(data.createdAt).format('LLL');
        data.deletedAt = !data.deletedAt ? "" : dayJs(data.deletedAt).format('DD.MM.YYYY HH:mm');
        return data;
      });

      return response;
    },
  },
};
</script>
