import { get, put } from '@/api/implementation/app';

import { getPaginated } from '@/api/implementation/app/wrapper.js';

function index(clientId, page, perPage, search, sortBy, descending, params) {
  return getPaginated(`client/${clientId}/webhook`, page, perPage, search, sortBy, descending, params);
}

function show(productId) {
  return get(`product/${productId}`);
}

function update(product) {
  return put(`product/${product.id}`, product);
}

export {
  index,
  show,
  update,
};
